<template>
  <!-- eslint-disable max-len -->
  <div class="hero-pattern min-h-screen flex flex-col justify-between items-center">
    <header class="pt-9">
      <div class="sm:flex sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <a href="/">
          <img src="logo-parcelex-white.svg" class="h-8" alt="Logo Parcelex"/>
        </a>
        <div class="uppercase tracking-widest font-light text-white">
          Para Negócios
        </div>
      </div>
    </header>
    <div class="flex-shrink-0 p-6 lg:p-0 w-full md:max-w-lg">
      <div class="p-6 lg:p-9 lg:pb-8 bg-white rounded-2xl mb-6 shadow-lg">
        <header class="mb-5 text-center">
          <img src="brand-icon-exclamation-circle.svg" class="w-btn mx-auto mb-3" alt="Ícone de atenção"/>
          <h1 class="font-bold text-xl">Erro no carregamento da página</h1>
        </header>
        <div class="text-gray-500 text-center space-y-4">
          <p>
            Ocorreu algum problema ao carregar o conteúdo da página. Por favor, tente <a href="#" class="hover:underline">recarregar a página no navegador</a> ou acesse novamente mais tarde.
          </p>
          <p>
            Persistindo o problema, entre em contato com <span class="font-bold">suporte@centrax.com.br</span>
          </p>
        </div>
      </div>
    </div>
    <footer class="text-center text-primary-500 pt-4 pb-6">&copy; Parcelex - {{ actualDate }}</footer>
  </div>
</template>

<script>

export default {
  name: '500',
  metaInfo: {
    title: 'Erro ao carregar a página',
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
    actualDate() {
      return new Date().getFullYear();
    },
  },
  methods: {
  },
  mounted() {},
};
</script>
